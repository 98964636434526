import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { SidebarComponent } from './sidebar.component';
import { CoreModule } from 'app/core/core.module';

@NgModule({
	declarations: [SidebarComponent],
	imports: [CommonModule, RouterModule, MatIconModule, CoreModule],
	exports: [SidebarComponent],
})
export class SidebarModule {}
