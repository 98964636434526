<!-- Loading bar -->
<div #hostElement class="body flex w-full">
	<fuse-loading-bar></fuse-loading-bar>

	<app-sidebar
		[user]="user"
		[isOpen]="showSidebar"
		(close)="closeSidebar()"
	></app-sidebar>

	<!-- Wrapper -->
	<div class="flex flex-col flex-auto w-full min-w-0">
		<!-- Header -->
		<app-toolbar
			[user]="user"
			[isSidebarOpen]="showSidebar"
			(toggleSidebar)="toggleSidebar()"
		></app-toolbar>

		<!-- Content -->
		<div class="flex flex-col flex-auto">
			<!-- <app-parallax-stars></app-parallax-stars> -->
			<div
				class="flex flex-col flex-auto rounded-tl-md rounded-tr-md md:rounded-tl-20 md:rounded-tr-none"
			>
				<!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
				<router-outlet *ngIf="true"></router-outlet>
			</div>
		</div>
	</div>
</div>
