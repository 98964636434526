import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { AvatarPictureComponent } from './avatar-picture/avatar-picture.component';
import { SocialsBarComponent } from './socialsBar/socials-bar/socials-bar.component';
import { ScrollShowHideDirective } from './scrollShowHide/scroll-show-hide.directive';
import { CustomIconComponent } from './custom-icon/custom-icon.component';

const modules = [
	CommonModule,
	FormsModule,
	ReactiveFormsModule,
	MatIconModule,
	MatTabsModule,
];

@NgModule({
	imports: [...modules],
	declarations: [
		AvatarPictureComponent,
		SocialsBarComponent,
		ScrollShowHideDirective,
		CustomIconComponent,
	],
	exports: [
		...modules,
		AvatarPictureComponent,
		SocialsBarComponent,
		CustomIconComponent,
	],
})
export class SharedModule {}
