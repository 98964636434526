import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { FuseLoadingBarModule } from '@fuse/components/loading-bar';
import { FuseFullscreenModule } from '@fuse/components/fullscreen/fullscreen.module';
import { LanguagesModule } from 'app/layout/common/languages/languages.module';
import { MessagesModule } from 'app/layout/common/messages/messages.module';
import { QuickChatModule } from 'app/layout/common/quick-chat/quick-chat.module';
import { SearchModule } from 'app/layout/common/search/search.module';
import { ShortcutsModule } from 'app/layout/common/shortcuts/shortcuts.module';
import { UserModule } from 'app/layout/common/user/user.module';
import { SharedModule } from 'app/shared/shared.module';
import { ClassyLayoutComponent } from 'app/layout/layouts/vertical/classy/classy.component';
import { UnlockGameModule } from 'app/layout/common/unlock-game/unlock-game.module';
import { NftDropModule } from 'app/modules/components/nft-drop/nft-drop.module';
import { AsideDocsModule } from 'app/layout/common/docs/docs.module';
import { ToolbarModule } from 'app/layout/common/toolbar/toolbar.module';
import { SidebarModule } from 'app/layout/common/sidebar/sidebar.module';
import { ParallaxStarsModule } from 'app/modules/auth/parallax-stars/parallax-stars.module';

@NgModule({
    declarations: [
        ClassyLayoutComponent
    ],
    imports     : [
        HttpClientModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        FuseFullscreenModule,
        FuseLoadingBarModule,
        FuseNavigationModule,
        LanguagesModule,
        MessagesModule,
        QuickChatModule,
        SearchModule,
        ShortcutsModule,
        UserModule,
        SharedModule,
        UnlockGameModule,
        NftDropModule,
        AsideDocsModule,
        SidebarModule,
        ToolbarModule,
        ParallaxStarsModule
    ],
    exports: [
        ClassyLayoutComponent
    ]
})
export class ClassyLayoutModule
{
}
