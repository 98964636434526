<div
	class="toolbar px-7 horizontal:p-4 md:p-10 w-full flex items-center horizontal:flex-col horizontal:h-screen horizontal:items-start z-50 print:hidden"
>
	<div
		class="toolbar-icons flex items-center horizontal:items-start gap-5 mr-5"
	>
		<mat-icon
			(click)="onToggleSidebar()"
			fontSet="material-icons-round"
			class="horizontal:mb-4 cursor-pointer hover:text-violet text-3xl text-white"
		>
			menu
		</mat-icon>

		<a
			[ngClass]="{ sideBarOpen: showLogo }"
			href="https://ready.gg"
			class="logo horizontal:absolute inline-block my-10"
		>
			<img
				src="assets/images/logo/logo-play.svg"
				class="min-w-24 w-24 h-auto"
			/>
		</a>
	</div>

	<div
		class="flex items-center horizontal:flex-1 horizontal:justify-evenly horizontal:items-start horizontal:flex-col-reverse ml-auto gap-5 overflow-hidden"
	>
		<ng-container *ngIf="user">
			<div
				class="flex-1 horizontal:flex-none overflow-hidden horizontal:w-10 hidden xs:block horizontal:block"
			>
				<wallet-connect></wallet-connect>
			</div>
			<notifications></notifications>
			<app-custom-icon
				[routerLink]="['/auth/sign-out']"
				icon="logout"
			></app-custom-icon>
		</ng-container>
		<app-custom-icon
			*ngIf="!isFullscreen && !isIphone"
			(click)="enableFullscreen()"
			icon="fullscreen"
		></app-custom-icon>

		<app-custom-icon
			*ngIf="isFullscreen && !isIphone"
			(click)="exitFullscreen()"
			icon="normalscreen"
		></app-custom-icon>
	</div>
</div>
