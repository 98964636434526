// Angular
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

// Rxjs
import { filter, Subject, takeUntil } from 'rxjs';

// Service
import { NftDropService } from './nft-drop.service';

@Component({
    selector: 'nft-drop',
    templateUrl: 'nft-drop.component.html',
    styleUrls: ['nft-drop.component.scss'],
})

export class NftDropComponent {
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    public isActive: boolean = true;
    public drops: any;
    public dropLoaded: boolean = false;

    constructor(
        private readonly _router: Router,
        private readonly _nftDropService: NftDropService,
    ) {
        this._router.events
        .pipe(
            takeUntil(this._unsubscribeAll),
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: any) => {
            this.checkUrl(event.url);
        });

        this._nftDropService.loadDrops();

        this._nftDropService.getDrops()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.drops = data;
            });

        this._nftDropService.getDropLoaded()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.dropLoaded = data;
            });

        this.checkUrl(this._router.url);
    }

    private checkUrl(url: string) {
        if (url.includes('drop')) {
            this.isActive = false;
        } else {
            this.isActive = true;
        }
    }
}
