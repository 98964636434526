// Angular
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

// Angular Material
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";

// Components
import { UnlockGameComponent } from "./unlock-game.component";

@NgModule({
    imports: [
        // Angular
        CommonModule,

        // Angular Material
        MatButtonModule,
        MatIconModule,
    ],
    declarations: [
        UnlockGameComponent,
    ],
    exports: [
        UnlockGameComponent,
    ],
})

export class UnlockGameModule { }
