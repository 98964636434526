import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
	{
		id: 'tester-sign-up',
		title: 'Tester Sign Up',
		type: 'basic',
		icon: 'menu-tester-signup',
		link: 'sign-up-flow'
	},
	// Temporary hide the achievements and leaderboards
	// {
	//     id: 'achievements',
	//     title: 'Achievements',
	//     type: 'basic',
	//     icon: 'menu-achievements',
	//     link: 'achievements/view-achievements',
	// },
	// {
	//     id: 'leaderboards',
	//     title: 'Leaderboards',
	//     type: 'basic',
	//     icon: 'menu-leaderboards',
	//     link: 'leaderboards/view-leaderboards',
	// },
	{
		id: 'beta-test',
		title: 'Beta Test',
		type: 'basic',
		icon: 'menu-beta-test',
		link: 'beta-test'
	},
	{
		id: 'community',
		title: 'Community',
		type: 'basic',
		icon: 'menu-community',
		link: 'community'
	},
	{
		id: 'play-menu',
		title: '$PLAY',
		type: 'basic',
		icon: 'menu-aura',
		link: 'play-menu'
	}
];

export const compactNavigation: FuseNavigationItem[] = [];

export const futuristicNavigation: FuseNavigationItem[] = [];

export const horizontalNavigation: FuseNavigationItem[] = [];
