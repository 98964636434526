<aside
	[ngClass]="{ mobile: isMobile }"
	class="sidebar flex flex-col items-center text-white pt-10 horizontal:pt-0 font-poppins"
	[class.open]="isOpen"
>
	<div class="sidebar__header">
		<button
			*ngIf="isOpen"
			mat-icon-button
			class="close-button hidden absolute top-5 left-5"
			(click)="onNavigation()"
		>
			<mat-icon class="text-white">close</mat-icon>
		</button>
		<div class="logo mb-10">
			<img src="assets/images/logo/logo-play.svg" alt="PLAY Logo" />
		</div>
		<div class="user-info flex flex-col items-center mb-10">
			<div class="avatar bg-white w-24 h-24 rounded-full overflow-hidden mb-2">
				<img src="assets/images/avatars/default.png" alt="User avatar" />
			</div>
			<h4 class="antialiased font-effraHeavy text-xl mb-2">
				{{ user?.displayName }}
			</h4>
			<p class="text-md text-gray font-light">{{ user?.email }}</p>
		</div>
	</div>
	<nav class="-ml-10 w-auto sidebar__navigation horizontal:flex-1">
		<ng-container *ngFor="let item of navigation">
			<a
				[routerLink]="item.link"
				routerLinkActive="active"
				class="nav-item inline-block nav-item flex items-center font-semibold text-sm py-3 px-7 md:px-1 no-underline"
				(click)="onNavigation()"
			>
				<mat-icon
					svgIcon="{{ item.icon }}"
					class="mat-icon-element text-white mr-6 w-6 h-6"
				></mat-icon>
				<span>{{ item.title }}</span>
			</a>
		</ng-container>
	</nav>
</aside>
