import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthCoreModule } from 'app/core/auth/auth.module';
import { HttpErrorViewerModule } from './http-error-viewer/http-error-viewer.module';
import { IconsModule } from 'app/core/icons/icons.module';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { WalletService } from 'app/core/services/wallet.service';
import { UserService } from 'app/core/services/user.service';
import { AchievementsService } from 'app/core/services/achievements.service';
import { ProjectSelectorService } from 'app/core/services/project-selector.service';
import { DeviceService } from 'app/core/services/device.service';

@NgModule({
	imports: [
		AuthCoreModule,
		HttpErrorViewerModule,
		IconsModule,
		TranslocoCoreModule,
	],
	providers: [
		AchievementsService,
		ProjectSelectorService,
		UserService,
		WalletService,
		DeviceService,
	],
})
export class CoreModule {
	/**
	 * Constructor
	 */
	constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
		// Do not allow multiple injections
		if (parentModule) {
			throw new Error(
				'CoreModule has already been loaded. Import this module in the AppModule only.',
			);
		}
	}
}
