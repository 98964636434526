import { Component } from '@angular/core';

@Component({
    selector: 'aside-docs',
    styleUrls: ['docs.component.scss'],
    template: `
        <div class="aside-docs-wrap">
            <div class="aside-docs">
                <a
                    href="https://readygames.gitbook.io/ready-sdk-documentation/"
                    target="_blank"
                >Go to docs</a>

                <a
                    href="https://readygames.gitbook.io/readyx/"
                    target="_blank"
                >Whitepaper</a>
            </div>
        </div>
    `
})

export class AsideDocsComponent { }
