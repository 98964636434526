import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthNewService } from '../auth/auth.new.service';
import { Config } from './config.type';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    private _conifg: BehaviorSubject<Config> = new BehaviorSubject<Config>({
        active: false,
        link: '',
    });

    constructor(
        private readonly _authService: AuthNewService,
    ) { }

    async get(): Promise<any> {
        /* temporary commented
        return this._authService.readymasterAppFireStore
            .collection('globalConfig')
            .doc('unlockGame')
            .get()
            .then(response => {
                const data: Config = response.data() as Config;

                console.log('Response config: ', data)

                this._conifg.next(data);
            })
        */
    }

    getConfig() {
        return this._conifg.getValue();
    }
}
