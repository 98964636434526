import { OnInit, ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";
import { ChangeDetectorRef } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from "@angular/router";
import { ProjectInfo } from "app/interface/project-selector.type";
import { ProjectSelectorService } from "app/core/services/project-selector.service";

const LAST_SELECTED_PROJECT = 'lastSelectedProject';
@Component({
  selector: 'app-project-selector',
  templateUrl: './project-selector.component.html',
  styleUrls: ['./project-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSelectorComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  projects: ProjectInfo[] = [];
  filteredProjects: ProjectInfo[] = [...this.projects];
  searchValue = '';
  selectedProject: string;

  constructor(
    private readonly _projectSelectorService: ProjectSelectorService,
    private readonly _route: ActivatedRoute,
    private readonly _changeDetectorRef: ChangeDetectorRef,
  ) {
    this._route.params
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(async (params) => {
        await this._getProjects();
      });
   }

  ngOnInit(): void {
  }

  public onToggleDropdown(isOpened: boolean) {
    this.searchValue = '';
    this.filteredProjects = [...this.projects];
  }
  public onProjectSelected(event) {
    const selectedProject = this.projects.find(project => project.name === event.value);
    if (selectedProject) {
      this._projectSelectorService.selectProject(selectedProject);
      localStorage.setItem(LAST_SELECTED_PROJECT, selectedProject.name);
    }
  }

  public applyFilter(value: string) {
    this.filteredProjects = this.projects.filter(project =>
      project.name.toLowerCase().includes(value.toLowerCase())
    );
  }
  private async _getProjects(): Promise<void> {
    const tags = ["player-dashboard"]
    const limit = 100;
    this.projects = await this._projectSelectorService.getProjectsByTags(tags, limit);
    this.filteredProjects = [...this.projects];
    if (this.filteredProjects.length > 0) {
      const lastSelectedProject = localStorage.getItem(LAST_SELECTED_PROJECT);
      const project = this.filteredProjects.find(project => project.name === lastSelectedProject);
      if (project) {
        this.selectedProject = lastSelectedProject;
        this._projectSelectorService.selectProject(project);
      } else {
        this.selectedProject = this.filteredProjects[0].name;
        this._projectSelectorService.selectProject(this.filteredProjects[0]);
        localStorage.setItem(LAST_SELECTED_PROJECT, this.selectedProject);
      }
    }
    this._changeDetectorRef.markForCheck();
  }
}
