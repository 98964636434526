// Angular
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

// Components
import { AsideDocsComponent } from "./docs.component";

@NgModule({
    imports: [
        // Angular
        CommonModule,
    ],
    declarations: [
        AsideDocsComponent,
    ],
    exports: [
        AsideDocsComponent,
    ],
})

export class AsideDocsModule { }
