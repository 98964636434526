// Angular
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

// Angular Material
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSnackBarModule } from "@angular/material/snack-bar";

// Components
import { NftDropComponent } from "./nft-drop.component";

@NgModule({
    imports: [
        // Angular
        CommonModule,
        RouterModule,

        // Angular Material
        MatProgressSpinnerModule,
        MatSnackBarModule,
    ],
    declarations: [
        NftDropComponent,
    ],
    exports: [
        NftDropComponent,
    ],
})

export class NftDropModule { }
