import { Component, ElementRef, OnInit } from '@angular/core';
import { AuthNewService } from 'app/core/auth/auth.new.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IAuthNewUserData } from 'app/interface/auth.new.interface';
import { DeviceService } from 'app/core/services/device.service';

@UntilDestroy()
@Component({
	selector: 'classy-layout',
	templateUrl: './classy.component.html',
	styleUrls: ['./classy.component.scss'],
})
export class ClassyLayoutComponent implements OnInit {
	user: IAuthNewUserData;
	showSidebar: boolean;
	isMobile: boolean;
	scrollingTimer: NodeJS.Timeout;
	scrollPosition = 0;
	hostElement: ElementRef;

	constructor(
		_device: DeviceService,
		private elementRef: ElementRef,
		public authService: AuthNewService,
	) {
		this.hostElement = this.elementRef;
		this.isMobile = _device.isMobile();
		this.showSidebar = !this.isMobile;
	}

	ngOnInit(): void {
		this.authService.user$.pipe(untilDestroyed(this)).subscribe((user) => {
			this.user = user;
		});
	}

	handleBgParallaxX() {
		this.scrollPosition = this.hostElement.nativeElement.scrollTop;
		const newBackgroundPositionX = this.scrollPosition * 0.25;

		if (newBackgroundPositionX < 460) {
			this.hostElement.nativeElement.style.backgroundPositionX =
				-newBackgroundPositionX + 'px';
			this.hostElement.nativeElement.style.backgroundPositionY = 'bottom';
		} else {
			this.hostElement.nativeElement.style.backgroundPositionX = '-460px';
		}
	}

	signOut(): void {
		this.authService.signOut();
	}

	toggleSidebar(): void {
		this.showSidebar = !this.showSidebar;
	}

	closeSidebar(): void {
		this.showSidebar = false;
	}
}
