import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { AngularFireModule } from "@angular/fire";

import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';

import { CoreModule } from 'app/core/core.module';
import { LayoutModule } from 'app/layout/layout.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { GdprBottomSheet } from './modules/components/gdpr/gdpr.component';
import { environment } from "../environments/environment";
import { AuthNewService } from './core/auth/auth.new.service';

function initializeAppFactory(authService: AuthNewService) {
    return () => authService.init();
}

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    declarations: [
        AppComponent,
        GdprBottomSheet,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        AngularFireModule.initializeApp(environment.readyGamesDeveloper, 'firebase'),

        // Angular Material
        MatBottomSheetModule,
        MatButtonModule,
        MatProgressSpinnerModule,

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        { provide: 'googleTagManagerId', useValue: "GTM-N3FMSVB" },
        { provide: 'googleTagManagerId', useValue: "GTM-TLB3325" },
        { provide: 'googleTagManagerId', useValue: "GTM-M3SGK7F" },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppFactory,
            deps: [AuthNewService],
            multi: true,
        }
    ],
})
export class AppModule { }
