// Angular
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

// Angular Material
import { MatSnackBar } from "@angular/material/snack-bar";

// Rxjs
import { BehaviorSubject, Observable } from "rxjs";

// Modules
import { AngularFireStorage } from '@angular/fire/storage';

// Service
import { AuthNewService } from "app/core/auth/auth.new.service";

// Environment
import { environment } from "environments/environment";
import { UrlService } from "app/core/services/url.service";

@Injectable({ providedIn: 'root' })
export class NftDropService extends UrlService {
    private readonly dropLoaded$ = new BehaviorSubject<boolean>(false);
    private readonly drops$ = new BehaviorSubject<any>(null);
    private readonly imageUrl: string;

    constructor(
        private readonly _http: HttpClient,
        private readonly _authService: AuthNewService,
        private readonly _storage: AngularFireStorage,
        private readonly _snackBar: MatSnackBar,
    ) {
        super();

        this.imageUrl = `${environment.firestoragePath}/virtual-items`;
    }

    public loadDrops() {
        const url = this.getUrlGen2('nft-drop-get');

        /* 404, need investigate & fix
        this._http.post(url, {
            token: this._authService.getUserToken()
        }).subscribe({
            next: (response) => {
                if (Object.keys(response).length >= 1) {
                    // Provisional fix until we decide to use more than one drop simultaneously
                    const key = Object.keys(response)[0];
                    const obj = response[key];
                    const ref = this._storage.refFromURL(`${this.imageUrl}/${obj.virtualItem.firebaseID}/images/thumbnail.png`);

                    ref.getDownloadURL().subscribe(link => {
                        obj.virtualItem.image = link;

                        this.drops$.next(obj);
                    });
                }
            },
            error: (error: any) => {
                console.log('NFT drop list error: ', error);
            }
        }).add(() => {
            this.dropLoaded$.next(true);
        });
        */
    }

    public getDrops(): Observable<any> {
        return this.drops$;
    }

    public getDropLoaded(): Observable<boolean> {
        return this.dropLoaded$;
    }

    public claimNft(nftDropId: string) {
        const url = this.getUrlGen2('nft-mint-virtual-item');

        return this._http.post(url, {
            nftDropId: nftDropId,
            token: this._authService.getUserToken()
        }).subscribe({
            next: (response) => {
                console.log('Drop claimed: ', response)

                this._snackBar.open('NFT Drop claimed successfully', 'Ok');

                this.drops$.next(null);
            },
            error: (error: any) => {
                console.log('Drop claimed failed: ', error);
            }
        });
    }
}
