import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { UrlService } from "./url.service";
import { environment } from 'environments/environment';
import { UserCurrenciesResponse, UserGlobalCurrenciesResponse } from 'app/interface/user';

@Injectable({
  providedIn: 'root'
})
export class UserService extends UrlService {

  constructor(
    private readonly _http: HttpClient,
  ) {
    super();
  }

  getFullProfile(userId: string) {
    return this._http.post(
        this.getUrlGen1('user-getFullProfile'),
        { userId, version: 2 },
    );

    /*
    {
        "userId": "string",
        "email": "string",
        "displayName": "string",
        "profilePicture": {
            "source": "string",
            "large": "string",
            "medium": "string",
            "small": "string"
        },
        "bio": "string",
        "lastAppPackageName": "string",
        "invisibleStatus": true,
        "currencies": [
            {
            "appIds": [
                "string"
            ],
            "name": "string",
            "quantity": 0
            }
        ]
    }
    */
  }

  getUserCurrencies() {
    return this._http.post<UserCurrenciesResponse>(
        this.getUrlGen1('currency-getUserCurrencies'),
        { appId: environment.projectId },
    );
  }

  getUserGlobalCurrencies() {
    return this._http.post<UserGlobalCurrenciesResponse>(
        this.getUrlGen1('currency-getUserGlobalCurrencies'),
        { appId: environment.projectId },
    );
  }
}
