import {
	Directive,
	ElementRef,
	HostListener,
	Input,
	SimpleChanges,
} from '@angular/core';
import { DeviceService } from 'app/core/services/device.service';

@Directive({
	selector: '[appScrollShowHide]',
})
export class ScrollShowHideDirective {
	@Input() appScrollShowHide: any;
	lastScrollTop = 0;
	enabled: boolean = false;

	constructor(
		private device: DeviceService,
		private el: ElementRef,
	) {}

	ngOnChanges(changes: SimpleChanges) {
		// Check if `appScrollShowHide` has changed
		if (changes.appScrollShowHide) {
			const mode = changes.appScrollShowHide.currentValue;

			if (mode === 'mobile') {
				this.enabled = this.device.isMobile();
			} else {
				this.enabled = true;
			}

			if (this.enabled)
				this.el.nativeElement.style.transform = 'translateY(100%)';
		}
	}

	@HostListener('window:scroll', [])
	onWindowScroll() {
		if (!this.enabled) return;
		const currentScroll =
			window.pageYOffset || document.documentElement.scrollTop;

		if (currentScroll > this.lastScrollTop && currentScroll > 50) {
			this.el.nativeElement.style.transform = 'translateY(0)';
		} else if (currentScroll <= 50) {
			this.el.nativeElement.style.transform = 'translateY(100%)';
		}

		this.lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // Update the last scroll position
	}
}
