import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-socials-bar',
	templateUrl: './socials-bar.component.html',
	styleUrls: ['./socials-bar.component.scss'],
})
export class SocialsBarComponent implements OnInit {
	@Input() bottomGradient?: boolean = false;

	constructor() {}

	ngOnInit(): void {}
}
