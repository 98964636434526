import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { ToolbarComponent } from './toolbar.component';
import { NotificationsModule } from '../notifications/notifications.module';
import { WalletConnectModule } from '../wallet/wallet.module';
import { ProjectSelectorModule } from './../project-selector/project-selector.module';
import { SharedModule } from 'app/shared/shared.module';
@NgModule({
	declarations: [ToolbarComponent],
	imports: [
		CommonModule,
		RouterModule,
		MatIconModule,
		SharedModule,
		NotificationsModule,
		ProjectSelectorModule,
		WalletConnectModule,
	],
	exports: [ToolbarComponent],
})
export class ToolbarModule {}
