import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { AuthNewService } from "app/core/auth/auth.new.service";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IAuthNewUserData } from 'app/interface/auth.new.interface';

@UntilDestroy()
@Component({
    selector       : 'user',
    templateUrl    : './user.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'user'
})
export class UserComponent implements OnInit, OnDestroy {
    static ngAcceptInputType_showAvatar: BooleanInput;
    user: IAuthNewUserData;

    @Input() showAvatar: boolean = true;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        public authService: AuthNewService
    ) {}

    ngOnInit() {
        this.authService.user$
            .pipe(untilDestroyed(this))
            .subscribe((user) => {
                this.user = user;

                this._changeDetectorRef.markForCheck();
            });
    }

    ngOnDestroy() {}

    updateUserStatus(status: string) {
        if (!this.user) { return; }

        console.log('updateUserStatus(): save data off');

        /* Update the user
        this._userService.update({
            ...this.user,
            status
        }).subscribe();
        */
    }

    signOut(): void
    {
        this.authService.signOut();
        this._router.navigate(['/auth/sign-out']);
    }
}
