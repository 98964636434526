// Angular
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { FormsModule } from '@angular/forms';

// Angular Material
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

// Components
import { ProjectSelectorComponent } from "./project-selector.component";

@NgModule({
    imports: [
        // Angular
        CommonModule,
        FormsModule,

        // Angular Material
        MatIconModule,
        MatDialogModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
    ],
    declarations: [
      ProjectSelectorComponent,
    ],
    exports: [
      ProjectSelectorComponent,
    ],
})

export class ProjectSelectorModule { }
