import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-custom-icon',
	templateUrl: './custom-icon.component.html',
	styleUrls: ['./custom-icon.component.scss'],
})
export class CustomIconComponent {
	@Input() icon: string = '';
	@Input() href?: string;
	@Input() routerLink?: string | any[];
	@Input() class: string = '';
	@Input() target: string = '_self';
	@Output() click = new EventEmitter<Event>();

	get iconPath(): string {
		return `assets/icons/${this.icon}.svg`;
	}

	handleClick(event: Event): void {
		if (!this.href && !this.routerLink) {
			this.click.emit(event);
		}
	}
}
