import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { WalletService } from 'app/core/services/wallet.service';
import { WalletV3Ext } from 'app/interface/wallet.interface';

@Component({
	selector: 'wallet-connect',
	styleUrls: ['wallet.component.scss'],
	encapsulation: ViewEncapsulation.None,
	templateUrl: 'wallet.component.html',
})
export class WalletConnectComponent implements OnDestroy {
	public wallet: WalletV3Ext | null = null;
	public label: string;
	public loading = false;

	constructor(private readonly _walletService: WalletService) {
		this._walletService.getRgnWallet();

		this._walletService.wallet$.subscribe((data) => {
			this.wallet = data;
			if (this.wallet?.wallets?.length) {
				this.label = this.wallet.wallets[0].address;
			} else {
				this.label = 'Connect wallet';
			}
		});
	}

	ngOnDestroy(): void {}

	public async connectWallet() {
		if (this.loading) return;

		this.loading = true;
		this._walletService.getRgnWallet();
		this.loading = false;
	}
}
