import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeviceService } from 'app/core/services/device.service';
import { IAuthNewUserData } from 'app/interface/auth.new.interface';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
	@Input() user: IAuthNewUserData;
	@Input() isOpen: boolean = true;
	@Output() close = new EventEmitter<void>();
	isMobile: boolean = false;
	navigation = [
		{
			id: 'tester-sign-up',
			title: 'Tester Sign Up',
			type: 'basic',
			icon: 'menu-tester-signup',
			link: 'sign-up-flow'
		},
		{
			id: 'beta-test',
			title: 'Beta Test',
			type: 'basic',
			icon: 'menu-beta-test',
			link: 'beta-test'
		},
		{
			id: 'community',
			title: 'Community',
			type: 'basic',
			icon: 'menu-community',
			link: 'community'
		},
		{
			id: 'play-menu',
			title: '$PLAY',
			type: 'basic',
			icon: 'menu-play',
			link: 'play-menu'
		},
		// {
		// 	id: 'leaderboards',
		// 	title: 'Leaderboards',
		// 	type: 'basic',
		// 	icon: 'menu-leaderboards',
		// 	link: 'leaderboards/view-leaderboards',
		// },
		{
			id: 'events',
			title: 'Events',
			type: 'basic',
			icon: 'reward_icon',
			link: 'events'
		}
	];

	constructor(private device: DeviceService) {
		this.isMobile = this.device.isMobile();
	}

	onNavigation(): void {
		if (this.device.isMobile()) this.close.emit();
	}
}
