import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProjectInfo, GetProjectsByTagsResponse } from '../../interface/project-selector.type';
import { UrlService } from "app/core/services/url.service";
import { BehaviorSubject, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectSelectorService extends UrlService {
  private selectedProject = new BehaviorSubject<ProjectInfo>(null);
  public selectedProject$ = this.selectedProject.asObservable();


  constructor(
    private readonly _http: HttpClient,
  ) {
    super();
  }

  public async getProjectsByTags(tags: string[], limit: number, startAfter: string = ""): Promise<ProjectInfo[]> {
    const url = this.getUrlGen1('projectsCollection-getByTags');
    const data = {
      tags,
      limit,
      startAfter,
    };
    const response = await lastValueFrom(this._http.post<GetProjectsByTagsResponse>(
      url,
      data));
    return response.projects;
  }
  public selectProject(project: ProjectInfo) {
    this.selectedProject.next(project);
  }
  public getSelectedProject(): ProjectInfo {
    return this.selectedProject.getValue();
  }
}
