import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';

import { MatIconRegistry } from '@angular/material/icon';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { AuthNewService } from 'app/core/auth/auth.new.service';
import { GdprBottomSheet } from './modules/components/gdpr/gdpr.component';
import { FuseSplashScreenService } from '@fuse/services/splash-screen';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	constructor(
		private readonly _authService: AuthNewService,
		private readonly _bottomSheet: MatBottomSheet,
		private readonly _domSanitizer: DomSanitizer,
		private readonly _gtmService: GoogleTagManagerService,
		private readonly _matIconRegistry: MatIconRegistry,
		private readonly _router: Router,
		private readonly _fuseSplashScreenService: FuseSplashScreenService
	) {
		this.initIcons();
		this._fuseSplashScreenService.hide();

		this._router.events.subscribe((item) => {
			// console.log('nav event', item);
			if (item instanceof NavigationEnd) {
				const gtmTag = {
					event: 'page',
					pageName: item.url
				};
				this._gtmService.pushTag(gtmTag);
			}
		});

		this._authService.user$.subscribe((data) => {
			if (data) {
				// TODO: enable when we will have a way to update GDPR flag in user record
				// this.checkGdpr(data);
			}
		});
	}

	private checkGdpr(userData: any) {
		if (!userData.hasApprovedGDPR) {
			setTimeout(() => {
				this._bottomSheet.open(GdprBottomSheet, {
					disableClose: true,
					hasBackdrop: false,
					closeOnNavigation: false,
					panelClass: 'ready-gdpr'
				});
			}, 5000);
		}
	}

	private initIcons() {
		this._matIconRegistry.addSvgIcon(
			'menu-beta-test',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/menu-beta-test.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'menu-achievements',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/trophy-3.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'menu-leaderboards',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/crown_white-leaderboard.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'menu-community',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/menu-community.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'menu-tester-signup',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/menu-tester-signup.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'menu-nft',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/menu-nft.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'menu-unlock-game',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/menu-unlock-game.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'menu-signout',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/menu-signout.svg'
			)
		);

		this._matIconRegistry.addSvgIcon(
			'header-notification',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/header-notification.svg'
			)
		);

		this._matIconRegistry.addSvgIcon(
			'aura-icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/aura-icon.svg'
			)
		);

		this._matIconRegistry.addSvgIcon(
			'aura-tab-icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/aura-tab.svg'
			)
		);

		this._matIconRegistry.addSvgIcon(
			'wallet-connect',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/wallet-connect.svg'
			)
		);

		this._matIconRegistry.addSvgIcon(
			'beta-test-entries-icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/readygg-logo.svg'
			)
		);

		this._matIconRegistry.addSvgIcon(
			'menu-play',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/play-token.svg'
			)
		);

		// old icons
		this._matIconRegistry.addSvgIcon(
			'android_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/android-os.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'ios_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/ios-logo.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'info_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/info.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'download_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/download.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'unity_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/unity-logo.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'facebook_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/facebook-f.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'google_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/google.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'retention_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/retention.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'engagement_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/engagement.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'installs_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/installs.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'aura_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/aura.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'aura_color_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/AuraIcon.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'balance_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/balance.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'balance_staked_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/balance_staked.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'balance_unvested_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/balance_unvested.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'calendar_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/calendar.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'calendar_percent_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/calendar_percent.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'calendar_vest_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/calendar_vest.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'cycle_supply_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/cycle_supply.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'cycle_user_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/cycle_user.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'fuseblock_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/fuseblock.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'fuseblock_balance_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/fuseblock_balance.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'fuseblock_balance_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/fuseblock_balance.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'governance_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/governance.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'install_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/install.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'mining_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/mining.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'price_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/price.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'refer_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/refer.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'reward_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/reward.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'staking_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/staking.svg'
			)
		);
		this._matIconRegistry.addSvgIcon(
			'user_group_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/user_group.svg'
			)
		);
	}
}
