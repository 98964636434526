import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetAchievementsWithUserDataResponse, GetAchievementsResponse, Achievement, AchievementWithUserData } from 'app/interface/achievements.type';
import { UrlService } from "app/core/services/url.service";
import { lastValueFrom } from 'rxjs';
import { ClaimResponse } from 'app/interface/achievements';



@Injectable({
  providedIn: 'root'
})
export class AchievementsService extends UrlService {

  constructor(
    private readonly _http: HttpClient,
  ) {
    super();
  }

  public async getByAppIdWithUserData(projectId: string, limit: number, startAfter: string = ""): Promise<AchievementWithUserData[]> {
    const url = this.getUrlGen1('achievements-getByAppIdsWithUserData');

    const data = {
      appIds: [projectId],
      limit,
      startAfter
    };

    const response = await lastValueFrom(
        this._http.post<GetAchievementsWithUserDataResponse>(url, data)
    );

    const achievements = response.achievements;
    const userAchievements = response.userAchievements;

    if (userAchievements != null) {
      for (let i = 0; i < achievements.length; ++i) {
        const achievement = achievements[i];
        achievement.userAchievement = userAchievements.find((item) => item.id == achievement.id);
      }
    }
    return achievements;
  }

  public async getByTagsWithUserData(
    tags: string[],
    limit: number,
    startAfter: string = "",
    withHistory: boolean = false):
    Promise<AchievementWithUserData[]> {
    const url = this.getUrlGen1('achievements-getByTagsWithUserData');
    const data = {
      tags,
      limit,
      startAfter,
      withHistory
    };

    const response = await lastValueFrom(
        this._http.post<GetAchievementsWithUserDataResponse>(url, data)
    );

    const achievements = response.achievements;
    const userAchievements = response.userAchievements;

    if (userAchievements != null) {
      for (let i = 0; i < achievements.length; ++i) {
        const achievement = achievements[i];
        achievement.userAchievement = userAchievements.find((item) => item.id == achievement.id);
      }
    }
    return achievements;
  }

  public async getByIdsWithUserData(ids: string[], withHistory: boolean = false): Promise<AchievementWithUserData[]> {
    const url = this.getUrlGen1('achievements-getByIdsWithUserData');
    const data = {
      ids,
      withHistory
    };

    const response = await lastValueFrom(
        this._http.post<GetAchievementsWithUserDataResponse>(url, data)
    );

    const achievements = response.achievements;
    const userAchievements = response.userAchievements;

    if (userAchievements != null) {
      for (let i = 0; i < achievements.length; ++i) {
        const achievement = achievements[i];
        achievement.userAchievement = userAchievements.find((item) => item.id == achievement.id);
      }
    }
    return achievements;
  }


  public async getByTags(tags: string[], limit: number, startAfter: string = ""): Promise<Achievement[]> {
    const url = this.getUrlGen1('achievements-getByTags');
    const data = {
      tags,
      limit,
      startAfter
    };

    const response = await lastValueFrom(
        this._http.post<GetAchievementsResponse>(url, data)
    );

    return response.achievements;
  }

  public async getByIds(ids: string[]): Promise<Achievement[]> {
    const url = this.getUrlGen1('achievements-getByIds');
    const data = { ids };

    const response = await lastValueFrom(
        this._http.post<GetAchievementsResponse>(url, data)
    );

    return response.achievements;
  }

  public async claimById(id: string, appId: string) {
    const url = this.getUrlGen1('achievements-claimById');

    const response = await lastValueFrom(
        this._http.post<ClaimResponse>(url, { appId, id })
    );

    return response;
  }
}
