import { Injectable } from '@angular/core';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { AuthNewService } from 'app/core/auth/auth.new.service';
import { ProjectSelectorService } from 'app/core/services/project-selector.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(
		private readonly _authService: AuthNewService,
		private readonly _projectSelectorService: ProjectSelectorService,
	) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		let newReq = req.clone();

		// If the access token didn't expire, add the Authorization header.
		// We won't add the Authorization header if the access token expired.
		// This will force the server to return a "401 Unauthorized" response
		// for the protected API routes which our response interceptor will
		// catch and delete the access token from the local storage while logging
		// the user out from the app.

		const selectedProject = this._projectSelectorService.getSelectedProject();
		const appId = selectedProject ? selectedProject.id : 'TnEsiSmGaWulc9IUL9ge';
		newReq = req.clone({
			headers: req.headers
				.set('Authorization', 'Bearer ' + this._authService.getUserToken())
				.set('app-id', appId),
		});

		// Response
		return next.handle(newReq).pipe(
			catchError((response) => {
				// Catch "401 Unauthorized" responses
				if (response instanceof HttpErrorResponse && response.status === 401) {
					// Sign out
					this._authService.signOut();

					// Reload the app
					location.reload();
				}

				throw new Error(
					response.error.message ? response.error.message : response.error,
				);
			}),
		);
	}
}
