import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from './core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

export const appRoutes: Route[] = [
	// Redirect to main View
	{ path: '', pathMatch: 'full', redirectTo: 'sign-up-flow' },

	// Redirect to Welcome after signin
	{ path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'sign-up-flow' },

	// Authentication Module - For guests
	{
		path: '',
		canActivate: [NoAuthGuard],
		canActivateChild: [NoAuthGuard],
		component: LayoutComponent,
		data: {
			layout: 'empty'
		},
		children: [
			{
				path: 'auth',
				loadChildren: () =>
					import('app/modules/auth/auth.module').then((m) => m.AuthModule)
			}
		]
	},

	// Admin routes
	{
		path: '',
		component: LayoutComponent,
		resolve: {
			initialData: InitialDataResolver
		},
		children: [
			{
				path: 'sign-up-flow',
				loadChildren: () =>
					import('app/modules/admin/tester-signup/tester-signup.module').then(
						(m) => m.TesterSignupModule
					),
				canActivate: [AuthGuard]
			},
			{
				path: 'beta-test',
				loadChildren: () =>
					import('app/modules/admin/beta-test/beta-test.module').then(
						(m) => m.BetaTestModule
					),
				canActivate: [AuthGuard]
			},
			{
				path: 'community',
				loadChildren: () =>
					import('app/modules/admin/community/community.module').then(
						(m) => m.CommunityModule
					),
				canActivate: [AuthGuard]
			},
			// { path: 'halo', loadChildren: () => import('app/modules/admin/halo/halo.module').then(m => m.HaloModule) },
			{
				path: 'nft',
				loadChildren: () =>
					import('app/modules/admin/nft/nft.module').then((m) => m.NftModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'achievements',
				loadChildren: () =>
					import('app/modules/admin/achievements/achievements.module').then(
						(m) => m.AchievementsModule
					),
				canActivate: [AuthGuard]
			},
			{
				path: 'leaderboards',
				loadChildren: () =>
					import('app/modules/admin/leaderboards/leaderboards.module').then(
						(m) => m.LeaderboardsModule
					),
				canActivate: [AuthGuard]
			},
			{
				path: 'events',
				loadChildren: () =>
					import('app/modules/admin/events/events.module').then(
						(m) => m.EventsModule
					)
			},
			{
				path: 'play-menu',
				loadChildren: () =>
					import('app/modules/admin/play-menu/play-menu.module').then(
						(m) => m.PlayMenuModule
					),
				canActivate: [AuthGuard]
			},

			// Not on menu
			{
				path: 'drop',
				loadChildren: () =>
					import('app/modules/admin/drop/drop.module').then(
						(m) => m.DropModule
					),
				canActivate: [AuthGuard]
			}
		]
	}
];
