import { Component, Input } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ConfigService } from 'app/core/config/config.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'unlock-game[location]',
    template: `
    <div class="mx-3" *ngIf="active">
        <button
            class="
                cursor-pointer transition-colors
                flex items-center justify-center
                h-10 px-4 rounded-full
                bg-gradient-primary text-white
            "
            (click)="unlockGame()"
            [ngClass]="{'w-full': full}"
        >
            <mat-icon class="text-white" svgIcon="menu-unlock-game"></mat-icon>
            Unlock Game Access
        </button>
    </div>
    `
})

export class UnlockGameComponent {
    @Input() location: 'aside'|'header';
    @Input() full: boolean;

    public active: boolean;
    public link: string;

    constructor(
        private readonly _gtmService: GoogleTagManagerService,
        private readonly _configService: ConfigService,
    ) {
        const config = this._configService.getConfig();

        this.link = config.link;
        this.active = config.active;
    }

    public unlockGame() {
        if (environment.production) {
            this._gtmService.pushTag({
                event: 'unlock_game',
                data: this.location,
            });
        }

        window.open(this.link, '_blank');
    }
}
