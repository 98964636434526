<div *ngIf="isActive" class="bg-white rounded-md md:rounded-2xl md:rounded-tr-none md:rounded-br-none overflow-hidden mb-5">
    <div *ngIf="!dropLoaded" class="flex gap-2 items-center justify-center h-10 text-white">
        <mat-spinner [diameter]="20"></mat-spinner>
        <small>Checking nft drops...</small>
    </div>

    <div class="w-full p-4 md:p-6 bg-gradient-primary shadow-xl nft_drop relative" *ngIf="dropLoaded && drops">
        <div class="w-full max-w-160 mx-auto flex flex-col text-white text-center relative z-20">
            <h1 class="text-xl font-bold">Congratulations! You won an NFT Drop!</h1>
            <h2 class="text-lg font-semibold text-gray">Click the button bellow to claim your drop right now!</h2>

            <button [routerLink]="['/drop']" class="nft_button">Claim NFT</button>
        </div>
    </div>
</div>
