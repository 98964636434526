import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";
import { AuthNewService } from "app/core/auth/auth.new.service";

@Component({
    templateUrl: 'gdpr.component.html',
    styleUrls: ['gdpr.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class GdprBottomSheet {
    public onProcess: boolean;

    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        private readonly _bottomSheetRef: MatBottomSheetRef<GdprBottomSheet>,
        private readonly _authService: AuthNewService,
    ) { }

    onConfirm(): void {
        this.onProcess = true;

        this._authService.confirmGdpr().then(response => {
            console.log('response on confirm: ', response)
        }).catch(error => {
            console.log('response on error: ', error)
            this.onProcess = false;
        }).finally(() => {
            this._bottomSheetRef.dismiss();
        });
    }

    onClose(): void {
        this._bottomSheetRef.dismiss();
    }
}
